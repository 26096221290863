import {useQueryClient} from 'react-query'
import {URL} from 'utils/urls'
import useMutatePlan from './use_mutate_plan'
import useMutateTrialQuestions from './use_mutate_trial_questions'
import useMutateUser from './use_mutate_user'

export default function useAllMutations() {
  const {deleteUser, updateUser, signoutUser, revokeAuthorization} = useMutateUser()
  const {updatePlan} = useMutatePlan()
  const {editTrialQuestions, createTrialQuestions} = useMutateTrialQuestions()
  const queryClient = useQueryClient()

  const mutations = {
    deleteUser: deleteUser,
    updateUser: updateUser,
    signoutUser: signoutUser,
    revokeAuthorization: revokeAuthorization,
    updatePlan: updatePlan,
    invalidateQueries: (queries = []) => queryClient.invalidateQueries(queries),
    invalidatePlanQuery: () => queryClient.invalidateQueries([URL.route.current_plan]),
    editTrialQuestions: editTrialQuestions,
    createTrialQuestions: createTrialQuestions
  }

  return {mutations}
}
