import {BrowserData, Mutations, ROUTES, Server} from '@brikit/tabjay-ui-kit'
import queries from 'lib/ui_kit_server/queries'
import {router} from 'next/router'
import LinkMetadata from 'services/link_metadata'
import TabjayExtension from 'services/tabjay_extension'
import {isExtension} from 'utils/helper_functions'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'
import api from './api'

export const signinFunctions = {
  email: async ({setRoute, params}) => {
    const res = await tabjayNodeApi.post(URL.login.magic_link, {email: params.email})
    return (res.status === 200) ? {route: ROUTES.MAGIC_LINK_SENT} : {}
  },
  google: () => {
    isExtension
      ? window.open(`${process.env.NEXT_PUBLIC_CLIENT_URL}${URL.login.google}`, '_blank') && window.close()
      : router.push(URL.login.google)
    return {}
  },
  apple: () => {
    isExtension
      ? window.open(`${process.env.NEXT_PUBLIC_CLIENT_URL}${URL.login.apple}`, '_blank') && window.close()
      : router.push(URL.login.apple)
    return {}
  }
}

export const authorizeFunctions = {
  google: () => {
    isExtension
      ? window.open(`${process.env.NEXT_PUBLIC_CLIENT_URL}${URL.authorize.google}`, '_blank') && window.close()
      : router.push(URL.authorize.google)
    return {}
  },
}

export const subscriptionFunctions = {
  buy: async data => {
    return await tabjayNodeApi.post(URL.route.stripe.portal, data).then(res => router.replace(res.data.url))
  },

  buyOneSeat: async data => {
    return await Mutations.mutations.updatePlan.mutate({
      id: data.planId,
      buyOneSeat: true
    })
  },

  extendTrial: async data => {
    if (data.trialCount == 1) return Mutations.mutations.createTrialQuestions.mutate(data)
    if (data.trialCount > 1) return Mutations.mutations.editTrialQuestions.mutate(data)
  },

  deactivatePlan: async data => {
    return await Mutations.mutations.editTrialQuestions.mutate({
      planId: data.plan.id,
      expectations: data.expectations,
      shortfall: data.shortfall,
      trialCount: data.trialCount,
      deactivate: true
    })
  },

  reactivatePlan: async data => {
    return await Mutations.mutations.updatePlan.mutate({
      id: data.plan.id,
      reactivate: true
    })
  },

  updateBilling: async data => {
    return await Mutations.mutations.updatePlan.mutate({
      id: data.planId,
      billingCycle: Number(data.billingCycle.value),
      billingCycleLabel: data.billingCycle.label,
      addMemberOption: Number(data.addMemberOption.value),
      updateBilling: true
    })
  }

}

export const initializeUiKitServerFunctions = () => {
  Server.getUrlMetadata = LinkMetadata.getUrl
  Server.signinFunctions = signinFunctions
  Server.authorizeFunctions = authorizeFunctions
  Server.subscriptionFunctions = subscriptionFunctions
  BrowserData.browser = TabjayExtension.browserData()
  Server.queries = queries
  Server.api = api
}