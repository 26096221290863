import {first, Task} from '@brikit/tabjay-ui-kit'
import _ from 'lodash'
import {getIdFromGoogleUrl} from 'utils/helper_functions'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'
import GoogleApi from './google_api'

const LinkMetadata = {

  getUrl: async function (url) {
    if (URL.isGoogleFile(url)) return getGoogleFileMetadata(url)

    // Check if the url is a task link
    const isTask = Task.isTaskLink(url)
    const link = isTask
      ? await taskLinkFromUrl(url)
      : (await tabjayNodeApi.post(URL.route.scrape_url, {urls: [url]})).data[0]

    const data = link && {
      url: link.url,
      name: link.name,
      planName: link.planName,
      description: link.description,
      image: isTask ? '' : link.image,
      images: link.images,
    }

    return {data}
  },

  getUrls: async function (urls) {
    const batches = _.chunk(urls, 25)
    const results = []

    for (const batch of batches) {
      const metadata = await tabjayNodeApi.post(URL.route.scrape_url, {urls: urls})
      results.push(metadata.data)
    }

    return _.flatten(results)
  },

  mergeMetaBrowserData: function (metadata, browserData) {
    const mergedData = metadata.map(md => {
      let browserItem = browserData.find(b => b.url === md.url)

      return {
        id: md.url,
        name: browserItem?.name || md.name || md.url,
        url: md.url,
        icon: md.icon || browserItem?.icon,
        image: md.image,
        description: md.description,
        lastVisited: browserItem?.last_visited,
        active: browserItem?.active,
        selected: browserItem?.selected,
        pinned: browserItem?.pinned,
        visitCount: browserItem?.visit_count
      }
    })

    return mergedData
  }
}

const taskLinkFromUrl = async (url) => {
  const shareCode = Task.shareCodeFromLink(url)
  const data = await tabjayNodeApi.get(`${URL.route.view_task}?shareCode=${shareCode}`)
  const task = first(data?.tasks)
  return task && {name: Task.composeName(task), planName: task.planName, url}
}

const getGoogleFileMetadata = async (url) => {
  const externalId = getIdFromGoogleUrl(url)
  const googleData = await GoogleApi.getFile(externalId)
  if (!googleData) {return null}

  const metadata = {url, name: googleData.title}
  return {data: metadata}
}

export default LinkMetadata
