import useMutationDefault from 'hooks/mutations/use_mutation_default'
import {useMutation} from 'react-query'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export default function useMutatePlan() {
  const {defaultMutation} = useMutationDefault()
  const queriesToInvalidate = [URL.route.current_users, URL.route.current_plan]

  const updatePlan = useMutation(
    (data) => tabjayNodeApi.patch(`${URL.route.plans}/${data.id}`, data),
    defaultMutation(queriesToInvalidate)
  )

  return {updatePlan}
}