const MARKETING_DOMAIN = 'https://www.tabjay.com'
const APP_DOMAIN = process.env.NEXT_PUBLIC_CLIENT_URL
const HELLO = '/hello'

const URL = {
  login: {
    apple: '/api/login/apple',
    google: '/api/login/google',
    magic_link: '/login/magic-link',
  },
  authorize: {
    google: '/api/login/google-authorize',
  },
  callback: {
    apple: '/login/apple/callback',
    google: '/login/google/callback',
    magic_link: '/login/magic-link/callback',
    google_authorize: '/login/google-authorize/callback',
  },
  route: {
    available_plans: '/available-plans',
    users: '/users',
    task: '/task',
    tasks: '/tasks',
    users_tasks: '/users-tasks',
    users_plans: '/users-plans',
    users_links: '/users-links',
    link: '/link',
    links: '/links',
    logout: '/logout',
    plan_deleted: '/plan-deleted',
    plan: '/plan',
    plans: '/plans',
    plan_settings: '/plan-settings',
    task_metadata: '/task_metadata',
    stripe_plans: (plan_id) => `/plan/${plan_id}/stripe-plans`,
    trial: '/trial',
    trial_questions: '/trial_questions',
    trial_ended_admin: (plan_id) => `/plan/${plan_id}/trial-ended-admin`,
    user_deactivated: (plan_id) => `/plan/${plan_id}/user-deactivated`,
    authorizations: '/authorizations',
    revoke_authorization: '/authorizations/revoke',
    refresh_token: '/authorizations/refresh-token',
    users_analytics: '/users_analytics',
    root: '/',
    current_plan: '/current-plan',
    current_user: '/current-user',
    current_users: '/current-users',
    scrape_url: '/link-metadata',
    sign_in: HELLO,
    update_link_hidden: '/users-links/update-link-hidden',
    search: ({filter = 'all', sort = 'last_visited', keyword = null}) => {
      const keywordParams = keyword ? `keyword=${keyword}` : 'keyword='
      return `/search?view=${filter}&sort=${sort}&${keywordParams}`
    },
    task_invitation: '/task-invitation',
    reset_share_links: '/tasks/reset-share-links',
    welcome: '/welcome',
    view_task: '/view-task',
    stripe: {
      portal: '/stripe/portal',
      success: '/stripe/success',
      webhook: '/api/stripe/webhook'
    }
  },
  marketing: {
    root: MARKETING_DOMAIN,
    terms: `${MARKETING_DOMAIN}/terms`,
    privacy: `${MARKETING_DOMAIN}/privacy`,
    help: 'https://tabjay.zendesk.com/hc/en-us'
  },
  canonical: {
    sign_in: `${APP_DOMAIN}/${HELLO}`
  },
  api: {
    google: {
      drive: 'https://www.googleapis.com/drive/v2/files/',
      docs: 'https://docs.googleapis.com/v1/documents/'
    },
    atlassian: {
      host: 'https://api.atlassian.com',
      confluence_content: (site, id) => `/ex/confluence/${site.id}/rest/api/content/${id}?expand=body.view`,
      accessible_sites: '/oauth/token/accessible-links'
    },
  },
  isGoogleFile(url) {
    return url.includes('docs.google.com') || url.includes('drive.google.com')
  },
  isConfluenceUrl(url) {
    return url.includes('atlassian.net/wiki')
  }
}

URL.auth_callbacks = {
  root: `${APP_DOMAIN}`,
  task: (task, shareKeyParams) => `${APP_DOMAIN}${URL.route.task}/${task}${shareKeyParams}`
}

export {URL}

