import {Token} from '@brikit/tabjay-ui-kit'
import GoogleApi from 'services/google_api'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

const thumbnailQueryFunction = async (link) => {
  const validTokens = Token.googleTokens()?.filter(t => Token.isTokenValid(t))
  if (_.isEmpty(validTokens)) {return null}
  const googleData = await GoogleApi.getFiles([link])
  return _.isEmpty(googleData) ? null : googleData[0].thumbnailLink
}

const queries = {
  currentUser: (userId) => userId && tabjayNodeApi.get(`${URL.route.current_user}`),
  plan: (userId, planId) => userId && planId && tabjayNodeApi.get(`${URL.route.current_plan}?id=${planId}`),
  availablePlans: (userIds) => tabjayNodeApi.get(`${URL.route.available_plans}`),
  viewTask: (shareCode) => shareCode && tabjayNodeApi.get(`${URL.route.view_task}?shareCode=${shareCode}`),
  signedInUsers: () => tabjayNodeApi.get(URL.route.current_users),
  googleThumbnail: (link) => thumbnailQueryFunction(link),
}

export default queries