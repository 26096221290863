import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

const api = {
  createLinks: ({links}) => tabjayNodeApi.post(URL.route.links, links.map(l => l.values())),
  createPlan: ({plan, userPlan}) => tabjayNodeApi.post(`${URL.route.plans}`, {...plan.values(), ...userPlan.values(), id: plan.id, userPlanId: userPlan.id}),
  createTask: ({task, userTask}) => tabjayNodeApi.post(URL.route.tasks, {...task.values(), ...userTask.values(), userTaskId: userTask.id}),
  createUser: ({user, userPlan}) => tabjayNodeApi.post(URL.route.users, {...user.values(), ...userPlan.values()}),
  createUserTask: ({userTask}) => tabjayNodeApi.post(URL.route.users_tasks, userTask.values()),
  createUserLink: ({userLink}) => tabjayNodeApi.post(URL.route.users_links, userLink.values()),

  updateTask: ({task}) => tabjayNodeApi.patch(`${URL.route.tasks}/${task.id}`, task.values()),
  updateLink: ({link}) => tabjayNodeApi.patch(`${URL.route.links}/${link.id}`, link.values()),
  updatePlan: ({plan}) => tabjayNodeApi.patch(`${URL.route.plans}/${plan.id}`, plan.values()),
  updateUser: ({user}) => tabjayNodeApi.patch(`${URL.route.users}/${user.id}`, user.values()),
  updateUserLink: ({linkUser}) => tabjayNodeApi.patch(`${URL.route.users_links}/${linkUser.id}`, linkUser.values()),
  updateUserPlan: ({planUser}) => tabjayNodeApi.patch(`${URL.route.users_plans}/${planUser.id}`, planUser.values()),
  updateUserTask: ({taskUser}) => tabjayNodeApi.patch(`${URL.route.users_tasks}/${taskUser.id}`, taskUser.values()),

  deleteUser: ({user}) => tabjayNodeApi.delete(`${URL.route.users}/${user.id}`),
  deleteUserPlan: ({planUser}) => tabjayNodeApi.delete(`${URL.route.users_plans}/${planUser.id}`),
  deleteTask: ({task}) => tabjayNodeApi.delete(`${URL.route.tasks}/${task.id}`, {data: task.values()}),
  deleteLink: ({link}) => tabjayNodeApi.delete(`${URL.route.links}/${link.id}`, {data: link.values()}),
  leaveTask: ({taskUser}) => tabjayNodeApi.delete(`${URL.route.users_tasks}/${taskUser.id}`, {data: taskUser.values()}),
}

export default api
